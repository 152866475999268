<template>
  <div class="wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">我要入园</el-breadcrumb-item>
      <el-breadcrumb-item>施工管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content-box mb16">
      <div class="fl-c-b bb mb16">
        <div class="title">项目名称：2号地块厂房建设项目</div>
        <div class="time">（施工单位：温州博弘电力有限公司）</div>
      </div>
      <div class="fl-box">
        <div class="contract">
          <div class="fs16 bold mb10">合同信息</div>
          <div class="party">甲方：温州博弘电力有限公司</div>
          <div class="party mb10">乙方：温州市鸿工建设有限公司</div>
          <div class="fl-box info-item">
            <div class="bold">工程地点：</div>
            <div class="text">温州市瓯江口百草坡</div>
          </div>
          <div class="fl-box info-item">
            <div class="bold">承包内容：</div>
            <div class="text">路牙石。面包砖路面</div>
          </div>
          <div class="fl-box info-item">
            <div class="bold">承包范围：</div>
            <div class="text">园内所有面包砖路面</div>
          </div>
          <div class="fl-box info-item">
            <div class="bold">承包方式：</div>
            <div class="text">包工。不包材料</div>
          </div>
          <div class="fl-box info-item">
            <div class="bold">质量要求：</div>
            <div class="text">GB50210-2001《建筑装饰装修工程质量验收规范》</div>
          </div>
          <div class="fl-box info-item">
            <div class="bold">工期要求：</div>
            <div class="text">2020年11月12日 — 2021年11月12日</div>
          </div>
          <div class="fl-box info-item">
            <div class="bold">项目造价：</div>
            <div class="text">50万</div>
          </div>
          <div class="fl-box info-item">
            <div class="bold">项目合同附件：</div>
            <a href="" class="a">《建设工程施工 合同》/下载附件</a>
          </div>
        </div>
        <div class="weekly">
          <div class="weekly-title">项目周报</div>
          <div class="fl-box">
            <div>
              <div class="countdown mb16"><span class="tag-title blue">施工倒计时/日历天</span> (2020年1月2日-2023年1月2日）</div>
              <div class="fl-box">
                <div class="calendar">1</div>
                <div class="calendar">0</div>
                <div class="calendar">0</div>
                <div class="calendar">0</div>
              </div>
            </div>
            <div class="total-progress">
              <span class="tag-title red mb16">项目总进度</span>
              <div class="total-progress-box fl-c-b">
                <div class="progress-box fl-1">
                  <div class="progress" style="width: 20%"></div>
                </div>
                <div class="progress-value">20%</div>
                <div class="look-btn" @click="go('Gant')">查看项目甘特图</div>
              </div>
            </div>
          </div>
          <div class="fl-c mb16">
            <div class="week-btn" :class="week == 1 ? 'active' : ''" @click="week = 1">上周周报</div>
            <div class="week-btn" :class="week == 2 ? 'active' : ''" @click="week = 2">本周计划</div>
          </div>
          <div class="table-box table-no-border">
            <el-table :data="table1" border>
              <el-table-column label="时间">
                <template slot-scope="scope">
                  <div class="table-lable bold" v-if="scope.$index == 0">工作内容</div>
                  <div class="table-lable bold" v-else>项目进度</div>
                </template>
              </el-table-column>
              <el-table-column label="周一  11/13" prop="column1"></el-table-column>
              <el-table-column label="周一  11/13" prop="column2"></el-table-column>
              <el-table-column label="周一  11/13" prop="column3"></el-table-column>
              <el-table-column label="周一  11/13" prop="column4"></el-table-column>
              <el-table-column label="周一  11/13" prop="column5"></el-table-column>
              <el-table-column label="周一  11/13" prop="column6"></el-table-column>
              <el-table-column label="周一  11/13" prop="column7"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box mb16">
      <div class="item-title bb mb16">施工人员信息</div>
      <div class="fl-box">
        <div>
          <span class="tag-title blue mb20">人员概况</span>
          <v-chart autoresize :options="option1" style="height: 146px; width: 270px"></v-chart>
        </div>
        <div style="width: calc(100% - 270px)">
          <span class="tag-title red mb20">施工负责人</span>
          <div class="fl-box">
            <div class="head">
              <el-avatar :size="50" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" class="avatar"></el-avatar>
              <div class="head-name">王某某</div>
              <div class="mb4">总负责人</div>
              <div>18800008888</div>
            </div>
            <div class="head-box">
              <swiper navigation :options="options">
                <swiper-slide v-for="(item, index) in 5" :key="index" class="head-item">
                  <el-avatar :size="50" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png" class="avatar"></el-avatar>
                  <div class="head-name">王某某</div>
                  <div class="mb4">总负责人</div>
                  <div>18800008888</div>
                </swiper-slide>
              </swiper>
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box mb16">
      <div class="item-title bb mb16">物料库存</div>
      <div class="table-box table-no-border">
        <el-table :data="table2">
          <el-table-column label="序号" type="index"> </el-table-column>
          <el-table-column label="物流名称" prop="column1"></el-table-column>
          <el-table-column label="库存" prop="column2"></el-table-column>
          <el-table-column label="库房" prop="column3"></el-table-column>
          <el-table-column label="管理员" prop="column4"></el-table-column>
          <el-table-column label="类型" prop="column5"></el-table-column>
          <el-table-column label="最近入库时间" prop="column6"></el-table-column>
          <el-table-column label="状态" prop="column7">
            <template slot-scope="scope">
              <div class="green1" v-if="scope.row.column7 == 1">充足</div>
              <div class="orange1" v-if="scope.row.column7 == 2">待补充</div>
              <div class="red1" v-if="scope.row.column7 == 3">不足</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="content-box mb16">
      <div class="item-title bb mb16">最近出入库记录</div>
      <div class="table-box table-no-border">
        <el-table :data="table3">
          <el-table-column label="序号" type="index"> </el-table-column>
          <el-table-column label="物流名称" prop="column1"></el-table-column>
          <el-table-column label="库存" prop="column2"></el-table-column>
          <el-table-column label="库房" prop="column3"></el-table-column>
          <el-table-column label="管理员" prop="column4"></el-table-column>
          <el-table-column label="类型" prop="column5"></el-table-column>
          <el-table-column label="最近入库时间" prop="column6"></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="content-box mb16">
      <div class="item-title bb mb16">设备管理</div>
      <div class="fl-box">
        <div class="equipment">
          <span class="tag-title blue mb20">在场设备</span>
          <v-chart autoresize :options="option2" style="height: 190px; width: 518px"></v-chart>
        </div>
        <div class="work">
          <span class="tag-title red">工作状态</span>
          <div class="clearfix">
            <div class="state-item fl" style="width: calc((100% - 12px) * 0.3)">
              <div class="state">工作</div>
              <div class="state-box"></div>
              <div class="state-value">60台</div>
              <div class="state-value">30%</div>
            </div>
            <div class="state-item fl" style="width: calc((100% - 12px) * 0.25)">
              <div class="state">待机</div>
              <div class="state-box"></div>
              <div class="state-value">50台</div>
              <div class="state-value">25%</div>
            </div>
            <div class="state-item fl" style="width: calc((100% - 12px) * 0.225)">
              <div class="state">停机</div>
              <div class="state-box"></div>
              <div class="state-value">45台</div>
              <div class="state-value">22.5%</div>
            </div>
            <div class="state-item fl" style="width: calc((100% - 12px) * 0.225)">
              <div class="state">离线</div>
              <div class="state-box"></div>
              <div class="state-value">25台</div>
              <div class="state-value">22.5%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box mb16">
      <div class="item-title bb mb16">设备实时状态</div>
      <div class="table-box table-no-border">
        <el-table :data="table4" border height="229">
          <el-table-column label="时间" prop="column1"></el-table-column>
          <el-table-column label="编号" prop="column2"></el-table-column>
          <el-table-column label="类型" prop="column3"></el-table-column>
          <el-table-column label="操作人" prop="column4"></el-table-column>
          <el-table-column label="实时油量" prop="column5"></el-table-column>
          <el-table-column label="状态" prop="column6">
            <template slot-scope="scope">
              <div class="orange2" v-if="scope.row.column6 == 1">待机</div>
              <div class="red2" v-if="scope.row.column6 == 2">停机</div>
              <div class="green2" v-if="scope.row.column6 == 3">工作</div>
              <div class="gray" v-if="scope.row.column6 == 4">离线</div>
            </template>
          </el-table-column>
          <el-table-column label="开机时间" prop="column7"></el-table-column>
          <el-table-column label="今日开机时长" prop="column8"></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper.scss';
// import Swiper core and required components
// import SwiperCore, { Navigation } from 'swiper';
// install Swiper components
// SwiperCore.use([Navigation]);
export default {
  data() {
    return {
      week: 1,
      table1: [
        {
          column1: '完成路面铺设100米',
          column2: '完成路面铺设100米',
          column3: '完成路面铺设100米',
          column4: '完成路面铺设100米',
          column5: '完成路面铺设100米',
          column6: '完成路面铺设100米',
          column7: '完成路面铺设100米',
        },
        { column1: '10%', column2: '10%', column3: '10%', column4: '10%', column5: '10%', column6: '10%', column7: '10%' },
      ],
      table2: [
        { column1: '水泥', column2: '50袋', column3: '1号', column4: '王某某', column5: '普通物料', column6: '2021年1月7日', column7: '1' },
        { column1: '面包砖', column2: '500块', column3: '1号', column4: '张某某', column5: '普通物料', column6: '2021年1月7日', column7: '2' },
        { column1: '沙子', column2: '1吨', column3: '1号', column4: '李某', column5: '普通物料', column6: '2021年1月7日', column7: '3' },
        { column1: '煤油', column2: '2吨', column3: '2号', column4: '孙某', column5: '易燃品', column6: '2021年1月7日', column7: '3' },
      ],
      table3: [{ column1: '水泥', column2: '50袋', column3: '1号', column4: '王某某', column5: '普通物料', column6: '2021年1月7日' }],
      table4: [
        {
          column1: '5-27 20:00:00',
          column2: 'FC255311233',
          column3: '挖掘机',
          column4: '王某云',
          column5: '6%',
          column6: '1',
          column7: '5-27 20:00:00',
          column8: '2小时22分',
        },
        {
          column1: '5-27 20:00:00',
          column2: 'FC255311233',
          column3: '渣土车',
          column4: '王某云',
          column5: '34%',
          column6: '2',
          column7: '5-27 20:00:00',
          column8: '2小时22分',
        },
        {
          column1: '5-27 20:00:00',
          column2: 'FC255311233',
          column3: '渣土车',
          column4: '王某云',
          column5: '56%',
          column6: '3',
          column7: '5-27 20:00:00',
          column8: '2小时22分',
        },
        {
          column1: '5-27 20:00:00',
          column2: 'FC255311233',
          column3: '渣土车',
          column4: '王某云',
          column5: '56%',
          column6: '4',
          column7: '5-27 20:00:00',
          column8: '2小时22分',
        },
        {
          column1: '5-27 20:00:00',
          column2: 'FC255311233',
          column3: '渣土车',
          column4: '王某云',
          column5: '56%',
          column6: '4',
          column7: '5-27 20:00:00',
          column8: '2小时22分',
        },
        {
          column1: '5-27 20:00:00',
          column2: 'FC255311233',
          column3: '渣土车',
          column4: '王某云',
          column5: '56%',
          column6: '4',
          column7: '5-27 20:00:00',
          column8: '2小时22分',
        },
      ],
      options: {
        slidesPerView: 4,
        observer: true,
        observeParents: true,
        // centeredSlides: true,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    go(name){
      this.$router.push({name})
    }
  },
  computed: {
    option1() {
      let data = [
        { value: 20, name: '水泥工' },
        { value: 10, name: '搬砖工' },
        { value: 20, name: '平地工' },
      ];
      // let total = data.reduce(function (a, b) {
      //   return a + b.value;
      // }, 0);
      return {
        color: ['#255ADA', '#14C53E', '#ED7A2C'],
        title: {},
        tooltip: {},
        legend: {
          formatter: (name) => {
            let res = data.filter((v) => v.name === name);
            // let percent = ((res[0].value / total) * 100).toFixed(2);
            // percent = isNaN(percent) ? 0 : percent;
            return '{name|' + name + ' }{percent|' + res[0].value + '人}';
          },
          left: '140',
          align: 'left',
          top: '45',
          icon: 'circle',
          orient: 'vertical',
          itemWidth: 8,
          itemHeight: 8,
          data: data.map((v) => {
            return v.name;
          }),
          textStyle: {
            rich: {
              name: {
                fontSize: 14,
                color: '#666',
                padding: [0, 4, 0, 3],
              },
              percent: {
                color: '#000',
                fontSize: 14,
                fontWeight: 'bold',
              },
            },
          },
        },
        series: [
          {
            type: 'pie',
            radius: [0, 60],
            center: [70, '50%'],
            data,
            itemStyle: {
              normal: {
                borderColor: '#EFF7FF',
                borderWidth: '1',
              },
            },
            label: {
              normal: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
          },
        ],
      };
    },
    option2() {
      let data = [
        { value: 12, name: '安全缓冲车' },
        { value: 56, name: '爆破空钻机' },
        { value: 32, name: '扒装机' },
        { value: 25, name: '泵车' },
        { value: 25, name: '叉车' },
        { value: 25, name: '塔吊' },
        { value: 25, name: '渣土车' },
      ];
      let total = data.reduce(function (a, b) {
        return a + b.value;
      }, 0);
      return {
        color: ['#255ADA', '#14B9C5', '#14C53E', ' #E0C219', '#ED7A2C', '#E22132', '#7325DA'],
        title: {
          text: total + '{dw|台}',
          top: 76,
          left: 115,
          textStyle: {
            fontSize: 24,
            color: '#000',
            rich: {
              dw: {
                color: '#333',
                fontSize: 12,
                padding: [0, 5],
              },
            },
          },
        },
        tooltip: {},
        legend: {
          formatter: (name) => {
            let res = data.filter((v) => v.name === name);
            let percent = ((res[0].value / total) * 100).toFixed(2);
            percent = isNaN(percent) ? 0 : percent;
            return '{name|' + name + ' }{percent|' + res[0].value + '台}';
          },
          left: '279',
          align: 'left',
          top: '10',
          icon: 'circle',
          orient: 'vertical',
          itemWidth: 8,
          itemHeight: 8,
          data: data.map((v) => {
            return v.name;
          }),
          textStyle: {
            rich: {
              name: {
                fontSize: 14,
                color: '#666',
                padding: [0, 8, 0, 6],
              },
              percent: {
                color: '#000',
                fontSize: 14,
                fontWeight: 'bold',
              },
            },
          },
        },
        series: [
          {
            type: 'pie',
            radius: [50, 80],
            center: [149, 90],
            data,
            itemStyle: {
              normal: {
                borderColor: '#EFF7FF',
                borderWidth: '1',
              },
            },
            label: {
              normal: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
          },
        ],
      };
    },
  },
  mounted() {},
  created() {
    // this.queryTable.search();
  },
};
</script>


<style lang='scss' scoped>
.content-box {
  background-color: #fff;
  padding: 0 20px 26px;
}

.title {
  line-height: 74px;
  font-size: 26px;
  color: #333333;
  font-weight: 600;
  padding-left: 14px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 28px;
    left: 0;
    width: 4px;
    height: 20px;
    background: #4393f8;
    border-radius: 3px;
  }
}
.item-title {
  line-height: 48px;
  font-size: 16px;
  color: #333333;
  font-weight: 600;
}
.time {
  font-size: 14px;
  color: #666666;
}
.mb16 {
  margin-bottom: 16px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb4 {
  margin-bottom: 4px;
}
.fs16 {
  font-size: 16px;
}
.tag-title {
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  position: relative;
  display: inline-block;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    opacity: 0.4;
  }
}
.tag-title.blue::after {
  background: #255ada;
}
.tag-title.red::after {
  background: #e22132;
}

.contract {
  background-image: linear-gradient(269deg, #f7fbff 0%, #eff7ff 100%);
  border-radius: 4px;
  width: 240px;
  margin-right: 20px;
  padding: 16px 16px 10px;
  color: #333333;
  .party {
    background: rgba(37, 90, 218, 0.2);
    border-radius: 4px;
    padding-left: 20px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: #255ada;
    margin-bottom: 8px;
  }
  .info-item {
    line-height: 26px;
    font-size: 14px;
    .bold {
      white-space: nowrap;
    }
    .text {
      color: #151515;
    }
    .a {
      color: #255ada;
    }
  }
}
.weekly {
  width: calc(100% - 260px);
  .weekly-title {
    font-size: 16px;
    color: #333333;
    line-height: 46px;
    font-weight: 600;
  }
  .countdown {
    font-size: 14px;
    color: #3f3f3f;
    line-height: 20px;
  }
  .calendar {
    background-image: linear-gradient(180deg, #dceeff 0%, #ffffff 100%);
    border-radius: 4px 4px 0 0;
    width: 104px;
    height: 110px;
    text-align: center;
    line-height: 110px;
    font-size: 26px;
    color: #333333;
    font-weight: 600;
    margin-right: 15px;
    margin-bottom: 32px;
  }
  .week-btn {
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    font-size: 14px;
    color: #666666;
    text-align: center;
    padding: 0 15px;
    line-height: 30px;
    cursor: pointer;
    margin-right: 16px;
    &.active,
    &:hover {
      border: 1px solid #255ada;
      color: #255ada;
    }
  }
}
.total-progress {
  width: calc(100% - 476px);
  .total-progress-box {
    background-image: linear-gradient(225deg, #fb8c8d 0%, #f65150 100%);
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 20px;
    height: 100px;
    .look-btn {
      border: 1px solid #ffffff;
      border-radius: 4px;
      height: 40px;
      font-size: 14px;
      color: #ffffff;
      line-height: 38px;
      text-align: center;
      padding: 0 20px;
      cursor: pointer;
    }
    .progress-box {
      background: rgba(255, 255, 255, 0.25);
      border-radius: 5px;
      height: 10px;
      margin-right: 10px;
      .progress {
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.45) 0%, #ffffff 100%);
        border-radius: 5px;
        height: 100%;
      }
    }
    .progress-value {
      margin-right: 23px;
      font-size: 24px;
      color: #ffffff;
    }
  }
}
.table-lable {
  color: #666666;
}
.head {
  background: #eff7ff;
  border-radius: 5px;
  width: 112px;
  height: 146px;
  text-align: center;
  color: #999999;
  margin-right: 20px;
  .avatar {
    margin-top: 8px;
    margin-bottom: 12px;
  }
  .head-name {
    color: #666666;
    margin-bottom: 6px;
  }
}
.head-box {
  width: calc(100% - 132px);
  background-image: linear-gradient(180deg, #eff7ff 0%, #ffffff 100%);
  border-radius: 5px;
  position: relative;
}
.head-item {
  text-align: center;
  color: #999999;
  position: relative;
  .avatar {
    margin-top: 8px;
    margin-bottom: 12px;
  }
  .head-name {
    color: #666666;
    margin-bottom: 6px;
  }
  &::after {
    position: absolute;
    content: '';
    top: 33px;
    left: -1px;
    width: 1px;
    height: 80px;
    background-color: #d8d8d8;
  }
}

.equipment {
  width: 550px;
  height: 262px;
  background: #eff7ff;
  padding: 16px;
  margin-right: 17px;
}
.work {
  width: calc(100% - 567px);
  padding: 16px 36px;
  background: #eff7ff;
  .state-item {
    padding-top: 40px;
    font-size: 14px;
    line-height: 14px;
    margin-right: 4px;
    text-indent: 16px;
  }

  .state-item .state {
    color: #666666;
    margin-bottom: 15px;
  }

  .state-box {
    height: 48px;
  }

  .state-value {
    font-weight: 600;
    color: #000;
    margin-top: 10px;
  }

  .state-item:nth-of-type(1) .state-box {
    background: rgba(37, 90, 218, 0.45);
    border: 1px solid #255ada;
  }

  .state-item:nth-of-type(2) .state-box {
    background: rgba(20, 197, 62, 0.45);
    border: 1px solid #14c53e;
  }

  .state-item:nth-of-type(3) .state-box {
    background: rgba(237, 122, 44, 0.45);
    border: 1px solid #ed7a2c;
  }

  .state-item:nth-of-type(4) .state-box {
    background: rgba(226, 33, 50, 0.45);
    border: 1px solid #e22132;
  }

  .state-item:nth-of-type(4) {
    margin-right: 0;
  }
}

.swiper-slide-duplicate-prev::after {
  width: 0;
}
.swiper-container {
  margin: 0 44px;
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 56px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  z-index: 9;
  cursor: pointer;
  &:focus{
    outline: none;
  }
}
.swiper-button-next {
  right: 20px;
  background: url(../../../assets/img/brainDecision/arrow_right_s.png) center no-repeat;
}
.swiper-button-prev {
  left: 20px;
  background: url(../../../assets/img/brainDecision/arrow_left_s.png) center no-repeat;
}
.orange1 {
  color: #e6a23c;
}
.red1 {
  color: #f56c6c;
}
.green1 {
  color: #67c23a;
}
.gray {
  color: #bfbfbf;
}
.orange2 {
  color: #ed7a2c;
}
.red2 {
  color: #e22132;
}
.green2 {
  color: #14c53e;
}
</style>